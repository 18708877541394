import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../loader/loader";
import Empty from "../loader/empty";
import { toast } from "react-toastify";

const NotApprovedStremer = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [count, setCount] = useState("");
  const [users, setUsers] = useState([]);
  const [approve, setApprove] = useState(null);
  const [loading, setloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 10;
  const shouldShowPagination = count > cardsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const Approveuser = async (e, id) => {
    e.preventDefault();
    try {
      setloading(true);
      await axios.patch(
        `/muzy/adminStreamer/approveRequestForStreamer?userId=${id}`,
        {},
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      setUsers([]);
      setApprove(!approve);
      setloading(false);
    } catch (error) {
      toast.error(error.message);
      setloading(false);
    }
  };

  useEffect(() => {
    const getAllusers = async () => {
      setloading(true);
      try {
        const res = await axios.get(
          `/muzy/adminStreamer/getAllNotApprovedStreamer?page=${currentPage}`,
          {
            headers: {
              Authorization: currentUser[0].token,
            },
          }
        );
        const usersWithBlockStatus = res.data.data.map((user) => ({
          ...user,
          isBlocked: user.isBlock,
        }));
        setloading(false);
        setUsers(usersWithBlockStatus);
        setCount(res.data?.totalCount);
      } catch (error) {
        console.log(error);
        setloading(false);
      }
    };

    getAllusers();
  }, [currentUser, currentPage, approve]);

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="text-center mb-4">
                  <h4 className="mb-0">Streamer Request</h4>
                </div>
                {/* style={{ height: "75vh" }} */}
                <div className="table-responsive ">
                  {loading ? (
                    <div
                      className="text-center d-flex justify-content-center align-items-center"
                      style={{
                        height: "inherit",
                      }}
                    >
                      <div className="">
                        <Loader />
                      </div>
                    </div>
                  ) : (
                    <table className="table text-start text-center table-bordered table-hover mb-0 position-relative">
                      <thead>
                        <tr className="text-white text-capitalize">
                          <th scope="col">#</th>
                          <th scope="col">Profile</th>
                          <th scope="col">User</th>
                          <th scope="col">Number</th>
                          {/* <th scope="col">Plan</th> */}
                          <th scope="col">Premium</th>
                          <th scope="col">Streamer</th>
                          <th scope="col">Followers</th>
                          <th scope="col">Following</th>
                          <th scope="col">Created at</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.length > 0 ? (
                          users.map((user, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td className="d-flex justify-content-center">
                                {user?.profileImage ? (
                                  <img
                                    className="rounded-circle flex-shrink-0"
                                    src={user.profileImage}
                                    alt="dp"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      objectFit: "cover",
                                      backgroundColor: "#ccc",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "50%",
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "white",
                                        fontSize: "24px",
                                      }}
                                    >
                                      <i className="fas fa-user"></i>
                                    </span>
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        color: "red",
                                        fontSize: "24px",
                                      }}
                                    >
                                      <i className="fas fa-times"></i>
                                    </span>
                                  </div>
                                )}
                              </td>

                              <td>
                                <div>
                                  <h4 className="text-capitalize">
                                    {user?.name}
                                  </h4>
                                  <h6>{user?.email}</h6>
                                </div>
                              </td>
                              <td>{user?.mobileNo}</td>
                              {/* <td>plan</td> */}
                              <td>
                                {user.isPremiumUser ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Premium
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Not Premium
                                  </span>
                                )}
                              </td>
                              <td>
                                {user.isStreamer ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Streamer
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Not Streamer
                                  </span>
                                )}
                              </td>
                              <td>{user?.followers?.length}</td>
                              <td>{user?.following?.length}</td>
                              <td>
                                {new Date(user.createdAt).toLocaleDateString()}
                              </td>
                              <td>
                                <div
                                  className="btn btn-sm btn-primary"
                                  style={{ marginLeft: "20px" }}
                                  onClick={(e) => Approveuser(e, user._id)}
                                >
                                  Accept
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <div>
                                <Empty />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            {users?.length > 0 && shouldShowPagination && (
              <div
                className="flex justify-center mt-4 mb-2"
                style={{ marginLeft: "20px" }}
              >
                {currentPage !== 1 && (
                  <button
                    className="pagination-button"
                    onClick={() => paginate(currentPage - 1)}
                  >
                    Previous
                  </button>
                )}
                {Array.from(
                  { length: Math.ceil(count / cardsPerPage) },
                  (_, i) => {
                    if (
                      i === 0 ||
                      i === currentPage - 1 ||
                      i === currentPage - 2 ||
                      i === currentPage ||
                      i === currentPage + 1 ||
                      i === Math.ceil(count / cardsPerPage) - 1
                    ) {
                      return (
                        <button
                          key={i}
                          className={`pagination-button ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </button>
                      );
                    } else if (
                      (i === currentPage - 3 && currentPage > 3) ||
                      (i === currentPage + 2 &&
                        currentPage < Math.ceil(count / cardsPerPage) - 2)
                    ) {
                      return <span key={i}>...</span>;
                    }
                    return null;
                  }
                )}
                {currentPage !== Math.ceil(count / cardsPerPage) && (
                  <button
                    className="pagination-button"
                    onClick={() => paginate(currentPage + 1)}
                  >
                    Next
                  </button>
                )}
              </div>
            )}

            <Footer />
          </div>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default NotApprovedStremer;
