import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import Empty from "../loader/empty";

const Advertisement = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [delid, setdelid] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 10;
  const shouldShowPagination = count > cardsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getAllusers = async () => {
    setloading(true);
    try {
      const res = await axios.get(
        `/muzy/adminUsers/all-ads?page=${currentPage}&limit=10`,
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      setUsers(res.data.data);
      setCount(res?.data?.totalAds);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  useEffect(() => {
    getAllusers();
  }, []);

  const BlockUser = async (e, id) => {
    e.preventDefault();
    setloading(true);
    try {
      await axios.patch(
        `/muzy/adminUsers/enableDisableAds?adId=${id}`,
        {},
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      setloading(false);
      getAllusers();
    } catch (error) {
      console.error("An error occurred:", error);
      setloading(false);
    }
  };

  const DeleteUSer = async (id) => {
    setloading(true);
    try {
      await axios.delete(`/muzy/adminUsers/delete-ads?adId=${id}`, {
        headers: {
          Authorization: currentUser[0]?.token,
        },
      });
      setdelid(null);
      getAllusers();
      setloading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setloading(false);
    }
  };

  const handleDeleteClick = (id) => {
    setdelid(id);
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    DeleteUSer(delid);
    setShowModal(false);
  };

  const handleModalCancel = () => {
    setdelid(null);
    setShowModal(false);
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="mb-0">Addvertisments</h4>
                  <Link
                    to="/add-addvertisement"
                    className="btn btn-sm btn-primary"
                  >
                    Add Addvertisments
                  </Link>
                </div>
                <div className="table-responsive " style={{ height: "60vh" }}>
                  {loading ? (
                    <div
                      className="text-center d-flex justify-content-center align-items-center"
                      style={{
                        height: "inherit",
                      }}
                    >
                      <div className="">
                        <Loader />
                      </div>
                    </div>
                  ) : users?.length > 0 ? (
                    <table className="table text-start text-center table-bordered table-hover mb-0 position-relative">
                      <thead>
                        <tr className="text-white text-capitalize">
                          <th scope="col">#</th>
                          <th scope="col">Profile</th>
                          <th scope="col">Title</th>
                          <th scope="col">Description</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">End Date</th>
                          <th scope="col">Created at</th>
                          <th scope="col">Activate</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {users?.map((user, index) => (
                          <tr
                            key={index}
                            className="text-white text-capitalize "
                          >
                            <td>{index + 1}</td>
                            <td className="d-flex justify-content-center">
                              {user?.bannerImage ? (
                                <img
                                  className="rounded-circle flex-shrink-0"
                                  src={user?.bannerImage}
                                  alt="dp"
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "cover",
                                    backgroundColor: "#ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%",
                                    position: "relative",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "white",
                                      fontSize: "24px",
                                    }}
                                  >
                                    <i className="fas fa-user"></i>
                                  </span>
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      color: "red",
                                      fontSize: "24px",
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center ">
                                <h5>{user?.title}</h5>
                              </div>
                            </td>
                            <td>{user?.description}</td>
                            <td>
                              {new Date(user?.startDate).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(user?.endDate).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(user?.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              <div className="form-check form-switch d-flex justify-content-center">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  role="switch"
                                  id={`flexSwitchCheckChecked_${user._id}`}
                                  onChange={(e) => BlockUser(e, user._id)}
                                  style={{
                                    backgroundColor: user?.isActive
                                      ? "green"
                                      : "red",
                                    borderColor: user?.isActive
                                      ? "green"
                                      : "red",
                                  }}
                                  checked={user?.isActive}
                                />
                              </div>
                            </td>
                            <td>
                              <Link
                                to="/Edit-addvertisement"
                                className="btn btn-sm btn-primary"
                                onClick={() =>
                                  localStorage.setItem("addid", user._id)
                                }
                              >
                                Edit
                              </Link>
                              <div
                                style={{ marginLeft: "20px" }}
                                className="btn btn-sm btn-primary"
                                onClick={() => handleDeleteClick(user._id)}
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {users.length > 0 && shouldShowPagination && (
              <div
                className="d-flex justify-content-end mx-4 my-4"
                style={{ marginLeft: "20px" }}
              >
                {currentPage !== 1 && (
                  <button
                    className="pagination-button"
                    onClick={() => paginate(currentPage - 1)}
                    style={{ borderRadius: "20px" }}
                  >
                    Previous
                  </button>
                )}
                {Array.from(
                  { length: Math.ceil(count / cardsPerPage) },
                  (_, i) => {
                    if (
                      i === 0 ||
                      i === currentPage - 1 ||
                      i === currentPage - 2 ||
                      i === currentPage ||
                      i === currentPage + 1 ||
                      i === Math.ceil(count / cardsPerPage) - 1
                    ) {
                      return (
                        <button
                          key={i}
                          className={`pagination-button rounded-circle ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </button>
                      );
                    } else if (
                      (i === currentPage - 3 && currentPage > 3) ||
                      (i === currentPage + 2 &&
                        currentPage < Math.ceil(count / cardsPerPage) - 2)
                    ) {
                      return <span key={i}>...</span>;
                    }
                    return null;
                  }
                )}
                {currentPage !== Math.ceil(count / cardsPerPage) && (
                  <button
                    className="pagination-button"
                    onClick={() => paginate(currentPage + 1)}
                    style={{ borderRadius: "20px" }}
                  >
                    Next
                  </button>
                )}
              </div>
            )}
            <Footer />
          </div>
          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
                backdropFilter: "blur(5px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              content: {
                position: "relative",
                maxWidth: "400px",
                width: "90%",
                background: "rgba(255, 255, 255, 0.95)",
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                padding: "30px",
                zIndex: 1001,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "black",
                }}
              >
                Are you sure you want to delete?
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn-secondary"
                  onClick={handleModalCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                <button className="btn btn-danger" onClick={handleModalConfirm}>
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default Advertisement;
