import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import Empty from "../loader/empty";

const Allplans = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [delid, setdelid] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(false);

  const getAllusers = async () => {
    setloading(true);
    try {
      const res = await axios.get(`/muzy/adminSubscription/plan/getPlanList`, {
        headers: {
          Authorization: currentUser[0]?.token,
        },
      });
      const usersWithBlockStatus = res.data.data.map((user) => ({
        ...user,
        activePlan: user.activePlan,
      }));
      setloading(false);
      setUsers(usersWithBlockStatus);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  useEffect(() => {
    getAllusers();
  }, []);

  const BlockUser = async (e, id) => {
    e.preventDefault();
    setloading(true);
    try {
      await axios.patch(
        `/muzy/adminSubscription/plan/activateOrNot?planId=${id}`,
        {},
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      setloading(false);
      getAllusers();
    } catch (error) {
      console.error("An error occurred:", error);
      setloading(false);
    }
  };

  const DeleteUSer = async (id) => {
    await axios.delete(`/muzy/adminSubscription/plan/delete?planId=${id}`, {
      headers: {
        Authorization: currentUser[0]?.token,
      },
    });
    setdelid(null);
    getAllusers();
  };

  const handleDeleteClick = (id) => {
    setdelid(id);
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    DeleteUSer(delid);
    setShowModal(false);
  };

  const handleModalCancel = () => {
    setdelid(null);
    setShowModal(false);
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="mb-0">Plans</h4>
                  <Link
                    to="/Add-plan"
                    className="btn btn-sm btn-primary"
                    // onClick={() => handleDeleteClick(user._id)}
                  >
                    Add New Plan
                  </Link>
                </div>
                <div className="table-responsive " style={{ height: "60vh" }}>
                  {loading ? (
                    <div
                      className="text-center d-flex justify-content-center align-items-center"
                      style={{
                        height: "inherit",
                      }}
                    >
                      <div className="">
                        <Loader />
                      </div>
                    </div>
                  ) : users?.length > 0 ? (
                    <table className="table text-start text-center table-bordered table-hover mb-0 position-relative">
                      <thead>
                        <tr className="text-white text-capitalize">
                          <th scope="col">Sr.no</th>
                          <th scope="col">Product Id</th>
                          <th scope="col">Plan</th>

                          <th scope="col">Duration</th>
                          <th scope="col">Price</th>
                          <th scope="col">Created at</th>
                          <th scope="col">Activate</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {users?.map((user, index) => (
                          <tr key={index} className="text-white">
                            <td>{index + 1}</td>
                            <td>
                              <div className="d-flex justify-content-center ">
                                <h5>{user?.ProductId}</h5>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center ">
                                <h5>{user?.name}</h5>
                              </div>
                            </td>

                            <td>{user?.duration}</td>
                            <td>{user?.price}</td>
                            <td>
                              {new Date(user.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              <div className="form-check form-switch d-flex justify-content-center">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  role="switch"
                                  id={`flexSwitchCheckChecked_${user._id}`}
                                  onChange={(e) => BlockUser(e, user._id)}
                                  style={{
                                    backgroundColor: user.activePlan
                                      ? "green"
                                      : "red",
                                    borderColor: user.activePlan
                                      ? "green"
                                      : "red",
                                  }}
                                  checked={user.activePlan}
                                />
                              </div>
                            </td>
                            <td>
                              <Link
                                to="/Edit-plan"
                                className="btn btn-sm btn-primary"
                                onClick={() =>
                                  localStorage.setItem("planid", user._id)
                                }
                              >
                                Edit
                              </Link>
                              <div
                                style={{ marginLeft: "20px" }}
                                className="btn btn-sm btn-primary"
                                onClick={() => handleDeleteClick(user._id)}
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
                backdropFilter: "blur(5px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              content: {
                position: "relative",
                maxWidth: "400px",
                width: "90%",
                background: "rgba(255, 255, 255, 0.95)",
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                padding: "30px",
                zIndex: 1001,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "black",
                }}
              >
                Are you sure you want to delete?
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn-secondary"
                  onClick={handleModalCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                <button className="btn btn-danger" onClick={handleModalConfirm}>
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default Allplans;
