// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-state {
  /* max-width: 750px; */
  margin: 40px auto;
  /* background: #cbccd3;
  box-shadow: 1px 2px 10px #e1e3ec; */
  border-radius: 4px;
}

.empty-state__message {
  color: #eb1616;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.85rem;
  /* text-transform: capitalize; */
}
`, "",{"version":3,"sources":["webpack://./src/layouts/loader/empty.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iBAAiB;EACjB;qCACmC;EACnC,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,gCAAgC;AAClC","sourcesContent":[".empty-state {\n  /* max-width: 750px; */\n  margin: 40px auto;\n  /* background: #cbccd3;\n  box-shadow: 1px 2px 10px #e1e3ec; */\n  border-radius: 4px;\n}\n\n.empty-state__message {\n  color: #eb1616;\n  font-size: 1.5rem;\n  font-weight: 500;\n  margin-top: 0.85rem;\n  /* text-transform: capitalize; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
