import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: [JSON.parse(localStorage.getItem("currentUser"))] || [],
  loading: false,
  error: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => ({ ...state, loading: true }),
    loginSuccess: (state, action) => {
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
      return { ...state, loading: false, currentUser: [action.payload] };
    },
    loginFailure: (state) => ({ ...state, loading: false, error: true }),
    logout: (state) => {
      localStorage.clear();
      localStorage.removeItem("currentUser");
      return { ...state, currentUser: [], loading: false, error: false };
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } =
  userSlice.actions;

export default userSlice.reducer;
