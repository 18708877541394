import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchItems, selectItems } from "../../redux/Slices/plansslice";
import Footer from "../Footer";
import Empty from "../loader/empty.jsx";
import Loader from "../loader/loader.jsx";

const Users = () => {
  const today = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30))
    .toISOString()
    .split("T")[0];
  const { currentUser } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [count, setCount] = useState("");
  const [users, setUsers] = useState([]);
  const [delid, setdelid] = useState(null);
  const [search, setsearch] = useState("");
  const [premuim, setpremuim] = useState("");
  const [plan, setPlan] = useState("");
  const [Streamers, setStreamers] = useState("");
  const [startDate, setstartDate] = useState(thirtyDaysAgo);
  const [endDate, setendDate] = useState(today);
  const [deleted, setdeleted] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 10;
  const shouldShowPagination = count > cardsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const items = useSelector(selectItems);
  const dispatch = useDispatch();

  const getAllusers = async () => {
    setloading(true);
    try {
      const res = await axios.get(
        `/muzy/adminUsers/getAllUsers?serach=${search}&isPremiumUser=${premuim}&isStreamer=${Streamers}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&planId=${plan}`,
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      const usersWithBlockStatus = res.data.data.map((user) => ({
        ...user,
        isBlocked: user.isBlock,
      }));
      setloading(false);
      setUsers(usersWithBlockStatus);
      setCount(res.data.totalCounts);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    getAllusers();
    dispatch(fetchItems());
  }, [currentUser, currentPage, deleted]);

  const handleSearch = () => {
    getAllusers();
  };

  const DeleteUSer = async (id) => {
    await axios.delete(`/muzy/adminUsers/deleteUser?userId=${id}`, {
      headers: {
        Authorization: currentUser[0]?.token,
      },
    });
    setdelid(null);
    setdeleted(!deleted);
  };

  const handleDeleteClick = (id) => {
    setdelid(id);
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    DeleteUSer(delid);
    setShowModal(false);
  };

  const handleModalCancel = () => {
    setdelid(null);
    setShowModal(false);
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="text-center">
                  <h4 className="">All Users</h4>
                </div>
                <div className="table-responsive ">
                  <div className="row mx-1">
                    <div className="form-floating mb-3 col-md-3 ">
                      <select
                        className="form-select text-white"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        onChange={(e) => setpremuim(e.target.value)}
                      >
                        <option value=""> Select Premium</option>
                        <option value="true">Premium</option>
                        <option value="false">Not Premium</option>
                      </select>
                      <label htmlFor="floatingSelect" style={{ left: "10px" }}>
                        Premium
                      </label>
                    </div>
                    <div className="form-floating mb-3 col-md-4 ">
                      <select
                        className="form-select text-white"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        onChange={(e) => setStreamers(e.target.value)}
                      >
                        <option value=""> Select Streamers</option>
                        <option value="true"> Streamers</option>
                        <option value="false">Not Streamers</option>
                      </select>
                      <label htmlFor="floatingSelect" style={{ left: "10px" }}>
                        Streamers
                      </label>
                    </div>
                    <div className="form-floating mb-3 col-md-4">
                      <select
                        className="form-select text-white"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        onChange={(e) => setPlan(e.target.value)}
                      >
                        <option value=""> Select Plan</option>
                        {items?.map((item, i) => {
                          return <option value={item._id}>{item.name}</option>;
                        })}
                      </select>
                      <label htmlFor="floatingSelect" style={{ left: "10px" }}>
                        Plan
                      </label>
                    </div>
                    <div className="form-floating mb-3  col-md-3">
                      <input
                        className="form-control text-white"
                        inputMode="numeric"
                        required
                        id="floatingInput check"
                        type="text"
                        autoComplete="off"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                      <label htmlFor="floatingInput" style={{ left: "10px" }}>
                        Search
                      </label>
                    </div>
                    <div className="form-floating mb-3  col-md-4">
                      <input
                        className="form-control text-white"
                        inputMode="numeric"
                        required
                        id="floatingInput check"
                        type="date"
                        placeholder="name, phone"
                        autoComplete="off"
                        value={startDate}
                        onChange={(e) => setstartDate(e.target.value)}
                      />
                      <label htmlFor="floatingInput" style={{ left: "10px" }}>
                        Start Date
                      </label>
                    </div>
                    <div className="form-floating mb-3  col-md-4">
                      <input
                        className="form-control text-white"
                        inputMode="numeric"
                        required
                        id="floatingInput check"
                        type="date"
                        autoComplete="off"
                        value={endDate}
                        onChange={(e) => setendDate(e.target.value)}
                      />
                      <label htmlFor="floatingInput" style={{ left: "10px" }}>
                        End Date
                      </label>
                    </div>
                    <div className="col-md-1 mt-md-n4 mt-sm-0">
                      <button
                        className="pagination-button "
                        style={{ borderRadius: "20px" }}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>

                  {loading ? (
                    <div
                      className="text-center d-flex justify-content-center align-items-center"
                      style={{
                        height: "inherit",
                      }}
                    >
                      <div className="">
                        <Loader />
                      </div>
                    </div>
                  ) : (
                    <table className="table text-start text-center table-bordered table-hover mb-0 position-relative">
                      <thead>
                        <tr className="text-white text-capitalize">
                          <th scope="col">#</th>
                          <th scope="col">Profile</th>
                          <th scope="col">User</th>
                          <th scope="col">Number</th>
                          <th scope="col">Premium</th>
                          <th scope="col">Streamer</th>
                          <th scope="col">Followers</th>
                          <th scope="col">Following</th>
                          <th scope="col">Created at</th>
                          <th scope="col">Delete User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.length > 0 ? (
                          users.map((user, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="d-flex justify-content-center">
                                {user?.profileImage ? (
                                  <img
                                    className="rounded-circle flex-shrink-0"
                                    src={user.profileImage}
                                    alt="dp"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      objectFit: "cover",
                                      backgroundColor: "#ccc",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "50%",
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "white",
                                        fontSize: "24px",
                                      }}
                                    >
                                      <i className="fas fa-user"></i>
                                    </span>
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        color: "red",
                                        fontSize: "24px",
                                      }}
                                    >
                                      <i className="fas fa-times"></i>
                                    </span>
                                  </div>
                                )}
                              </td>

                              <td>
                                <div
                                  className="fw-normal fs-5 "
                                  style={{ color: "#cbccd3" }}
                                >
                                  <div className="text-capitalize">
                                    {user?.name}
                                  </div>
                                  <div>{user?.email}</div>
                                </div>
                              </td>
                              <td>{user?.mobileNo}</td>
                              {/* <td>plan</td> */}
                              <td>
                                {user.isPremiumUser ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Premium
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Not Premium
                                  </span>
                                )}
                              </td>
                              <td>
                                {user.isStreamer ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Streamer
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Not Streamer
                                  </span>
                                )}
                              </td>
                              <td>{user?.followersCount}</td>
                              <td>{user?.followingCount}</td>
                              <td>
                                {new Date(user.createdAt).toLocaleDateString()}
                              </td>
                              <td>
                                <div
                                  style={{ marginLeft: "20px" }}
                                  className="btn btn-sm btn-primary"
                                  onClick={() => handleDeleteClick(user._id)}
                                >
                                  Delete
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <div>
                                <Empty />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            {users.length > 0 && shouldShowPagination && (
              <div
                className="d-flex justify-content-end mx-4 my-3"
                style={{ marginLeft: "20px" }}
              >
                {currentPage !== 1 && (
                  <button
                    className="pagination-button "
                    onClick={() => paginate(currentPage - 1)}
                    style={{ borderRadius: "20px" }}
                  >
                    Previous
                  </button>
                )}
                {Array.from(
                  { length: Math.ceil(count / cardsPerPage) },
                  (_, i) => {
                    if (
                      i === 0 ||
                      i === currentPage - 1 ||
                      i === currentPage - 2 ||
                      i === currentPage ||
                      i === currentPage + 1 ||
                      i === Math.ceil(count / cardsPerPage) - 1
                    ) {
                      return (
                        <button
                          key={i}
                          className={`pagination-button rounded-circle ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </button>
                      );
                    } else if (
                      (i === currentPage - 3 && currentPage > 3) ||
                      (i === currentPage + 2 &&
                        currentPage < Math.ceil(count / cardsPerPage) - 2)
                    ) {
                      return <span key={i}>...</span>;
                    }
                    return null;
                  }
                )}
                {currentPage !== Math.ceil(count / cardsPerPage) && (
                  <button
                    className="pagination-button "
                    onClick={() => paginate(currentPage + 1)}
                    style={{ borderRadius: "20px" }}
                  >
                    Next
                  </button>
                )}
              </div>
            )}

            <Footer />
          </div>
          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
                backdropFilter: "blur(5px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              content: {
                position: "relative",
                maxWidth: "400px",
                width: "90%",
                background: "rgba(255, 255, 255, 0.95)",
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                padding: "30px",
                zIndex: 1001,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "black",
                }}
              >
                Are you sure you want to delete?
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn-secondary"
                  onClick={handleModalCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                <button className="btn btn-danger" onClick={handleModalConfirm}>
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default Users;
