import React, { useEffect, useState } from "react";
import "./Termss.css";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../../loader/loader";
import { Link } from "react-router-dom";
import Footer from "../../Footer";

const Termsofservices = () => {
  const [loading, setloading] = useState(false);
  const [content, setContent] = useState(null);
  const [Contenttitle, setContenttitle] = useState("");
  const { currentUser } = useSelector((state) => state.user);

  const getAllusers = async () => {
    setloading(true);
    try {
      const res = await axios.get(`/muzy/privacy/companyGetPrivacy`, {
        headers: {
          Authorization: currentUser[0]?.token,
        },
      });
      setContent(res.data.getData[0].privacyContent.toString());
      setContenttitle(res.data.getData[0].title);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllusers();
  }, []);

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md navbar-dark bg-dark-secondary">
          <div className="container">
            <img
              src="/assests/img/Logo.svg"
              alt="logo"
              style={{ height: "40px", width: "200px" }}
            />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-start navastyel"
              id="navbarCollapse"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link font-weight-100" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link font-weight-100"
                    to="/TermsConditions"
                  >
                    Terms & Condition
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link font-weight-100"
                    to="/privacypolicy"
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {loading ? (
        <div
          className="text-center d-flex justify-content-center align-items-center"
          style={{
            height: "inherit",
          }}
        >
          <div className="">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <section id="terms-of-service">
            <div className="card2">
              <h1 className="primary-heading">{Contenttitle}</h1>
              <div>
                {content == null ? (
                  <></>
                ) : (
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: content }}
                      style={{
                        margin: "67px",
                      }}
                    ></div>
                  </>
                )}
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default Termsofservices;
