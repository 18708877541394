import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../loader/loader";

const Settings = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [taxtype, setTaxtype] = useState("");
  const [tax, setTax] = useState("");
  const [maxtax, setMaxtax] = useState("");
  const [commissiontype, setCommissiontype] = useState("");
  const [commission, setCommission] = useState("");
  const [maxcommission, setMaxcommission] = useState("");
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const getSettings = async () => {
    setloading(true);
    try {
      const res = await axios.get(`/muzy/adminUsers/get-setting`, {
        headers: {
          Authorization: currentUser[0]?.token,
        },
      });
      setloading(false);
      setTaxtype(res.data.data[0].tax_type);
      setTax(res.data.data[0].tax_value);
      setMaxtax(res.data.data[0].max_tax);
      setCommissiontype(res.data.data[0].comission_type);
      setCommission(res.data.data[0].comission_value);
      setMaxcommission(res.data.data[0].max_comission);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  useEffect(() => {
    getSettings();
  }, []);

  const handleAddPlans = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      await axios.post(
        "/muzy/adminUsers/setting",
        {
          tax_type: taxtype,
          tax_value: tax,
          max_tax: maxtax,
          comission_type: commissiontype,
          comission_value: commission,
          max_comission: maxcommission,
        },
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );

      toast.success("Settings Saved successfully!");
      await getSettings();
      setloading(false);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again later.");
      setloading(false);
    }
  };
  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="mb-0">Settings</h4>
                </div>
                <div className="container-fluid">
                  <div className="row h-100 align-items-center justify-content-center">
                    <div
                      className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 text-white"
                      style={{ width: "70%" }}
                    >
                      {loading ? (
                        <>
                          <div
                            className="text-center d-flex justify-content-center align-items-center"
                            style={{
                              height: "inherit",
                            }}
                          >
                            <div className="">
                              <Loader />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <form onSubmit={handleAddPlans}>
                            <div className="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ width: "47%" }}>
                                  <div className="form-floating mb-3">
                                    <select
                                      value={taxtype}
                                      //   required
                                      className="form-select text-white"
                                      id="floatingSelect"
                                      onChange={(e) =>
                                        setTaxtype(e.target.value)
                                      }
                                    >
                                      <option value="">Select Tax Type</option>
                                      <option value="percentage">
                                        Percentage
                                      </option>
                                      <option value="flat">Flat</option>
                                      {/* <option value="inr">INR ₹ </option>
                              <option value="kwd">KWD د.ك </option>
                              <option value="usd">USD $ </option>
                              <option value="kwd">EURO € </option> */}
                                    </select>
                                    <label htmlFor="floatingSelect">
                                      Tax Type
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3 ">
                                    <input
                                      className="form-control text-white"
                                      onChange={(e) =>
                                        setTax(
                                          e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .replace(/(\..*?)\..*/g, "$1")
                                        )
                                      }
                                      inputMode="numeric"
                                      //   required
                                      id="floatingInput check"
                                      type="text"
                                      maxlength="2"
                                      autoComplete="off"
                                      value={tax}
                                    />
                                    <label htmlFor="floatingInput">
                                      Tax Value
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      className="form-control text-white"
                                      inputmode="numeric"
                                      id="floatingInput check"
                                      type="text"
                                      //   required
                                      maxlength="2"
                                      autoComplete="off"
                                      value={maxtax}
                                      onChange={(e) =>
                                        setMaxtax(
                                          e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .replace(/(\..*?)\..*/g, "$1")
                                        )
                                      }
                                    />
                                    <label htmlFor="floatingInput">
                                      Maximum Tax Value
                                    </label>
                                  </div>
                                </div>
                                <div style={{ width: "47%" }}>
                                  <div className="form-floating mb-3">
                                    <select
                                      value={commissiontype}
                                      //   required
                                      className="form-select text-white"
                                      id="floatingSelect"
                                      onChange={(e) =>
                                        setCommissiontype(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        Select Comission Type
                                      </option>
                                      <option value="percentage">
                                        percentage
                                      </option>
                                      <option value="flat">Flat</option>
                                      {/* <option value="inr">INR ₹ </option>
                              <option value="kwd">KWD د.ك </option>
                              <option value="usd">USD $ </option>
                              <option value="kwd">EURO € </option> */}
                                    </select>
                                    <label htmlFor="floatingSelect">
                                      Comission Type
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3 ">
                                    <input
                                      className="form-control text-white"
                                      onChange={(e) =>
                                        setCommission(
                                          e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .replace(/(\..*?)\..*/g, "$1")
                                        )
                                      }
                                      inputMode="numeric"
                                      //   required
                                      id="floatingInput check"
                                      type="text"
                                      maxlength="2"
                                      autoComplete="off"
                                      value={commission}
                                    />
                                    <label htmlFor="floatingInput">
                                      Comission Value
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      className="form-control text-white"
                                      inputmode="numeric"
                                      id="floatingInput check"
                                      type="text"
                                      //   required
                                      maxlength="2"
                                      autoComplete="off"
                                      value={maxcommission}
                                      onChange={(e) =>
                                        setMaxcommission(
                                          e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .replace(/(\..*?)\..*/g, "$1")
                                        )
                                      }
                                    />
                                    <label htmlFor="floatingInput">
                                      Maximum Comission Value
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary py-3 w-100 mb-4"
                              >
                                Update Settings
                              </button>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default Settings;
