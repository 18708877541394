import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const About = () => {
  const [content, setContent] = useState("ameen");

  const handleChange = (value) => {
    setContent(value);
  };

  return (
    <div>
      {" "}
      <div className="">
        <div className="container-fluid pt-4 px-4 h-100">
          <div className="bg-secondary text-center rounded p-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h4 className="mb-0">About Us</h4>
            </div>
            <div style={{ height: "auto" }}>
              <ReactQuill
                style={{ background: "white", color: "black", height: "auto" }}
                value={content}
                onChange={handleChange}
                scrollingContainer="#scrolling-container"
                theme="snow"
              />
              <button className="my-3 btn btn-dark">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
