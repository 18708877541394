import { configureStore, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { useSelector } from "react-redux";

const initialState = {
  items: [],
  loading: false,
  error: null,
};

const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    fetchItemsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchItemsSuccess(state, action) {
      state.loading = false;
      state.items = action.payload;
    },
    fetchItemsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchItemsStart, fetchItemsSuccess, fetchItemsFailure } =
  itemsSlice.actions;

export default itemsSlice.reducer;
export const fetchItems = (id) => async (dispatch, getState) => {
  try {
    dispatch(fetchItemsStart());
    const {
      user: { currentUser },
    } = getState();

    const token = currentUser[0]?.token; // Replace with your authorization token
    const config = {
      headers: {
        Authorization: token,
      },
    };
    const response = await axios.get(
      `/muzy/adminSubscription/plan/getPlanList`,
      config
    );
    dispatch(fetchItemsSuccess(response.data));
  } catch (error) {
    dispatch(fetchItemsFailure(error.message));
  }
};
export const selectItems = (state) => state.plan.items.data;
