import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import routes from "./routes";
import Navbar from "./layouts/Navbar";
import ErrorPage from "./layouts/loader/error.jsx";
import Homepage from "./layouts/homepage/Homepage.jsx";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      if (currentUser && currentUser.token) {
        // Check if token is expired
        const tokenExpiration = getTokenExpiration(currentUser.token);
        if (tokenExpiration && new Date() > tokenExpiration) {
          // Token is expired, redirect to signin
          localStorage.removeItem("currentUser");
          navigate("/signin");
        }
      }
    };

    checkTokenExpiration();
    // Check token expiration every minute
    const intervalId = setInterval(checkTokenExpiration, 60000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  const getTokenExpiration = (token) => {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));

      if (payload.exp) {
        return new Date(payload.exp * 1000);
      }
    } catch (error) {
      console.error("Error parsing token:", error);
    }
    return null;
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });

  const getRoutesArray = (allRoutes) => {
    return allRoutes.map((route) => route.route);
  };

  const shouldShowNavbar =
    !location.pathname.includes("signin") &&
    !location.pathname.includes("privacypolicy") &&
    !location.pathname.includes("TermsConditions") &&
    !location.pathname.includes("deleteaccount") &&
    getRoutesArray(routes).includes(location.pathname);

  return (
    <>
      <div
        className={`content ${
          shouldShowNavbar ? (showButton ? "open" : "") : "falsehide"
        }`}
      >
        <ToastContainer />
        {shouldShowNavbar && <Navbar set={showButton} setset={setShowButton} />}

        <Routes>
          {getRoutes(routes)}
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/dashboard" element={<Navigate to="/signin" />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
