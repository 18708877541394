import Advertisement from "./layouts/Advertisement";
import Addadvertisement from "./layouts/Advertisement/AddAdvertisement";
import EditAdvertisement from "./layouts/Advertisement/EditAdvertisement";
import Signin from "./layouts/Authentication/Sign-in";
import Dashboard from "./layouts/Dashboard";
import Deleteaccount from "./layouts/Deleteaccount/Deleteaccount";
import Events from "./layouts/Events/events";
import NotApprovedStremer from "./layouts/NotApprovedStreamer";
import About from "./layouts/Pages/About/About";
import Contact from "./layouts/Pages/Contact/Contact";
import Terms from "./layouts/Pages/Termsandcondition/Terms";
import Allplans from "./layouts/Plans";
import Addplans from "./layouts/Plans/AddPlans";
import Editplans from "./layouts/Plans/EditPlans";
import TransferRefund from "./layouts/Refund";
import Settings from "./layouts/Settings";
import Alltransactions from "./layouts/Transactions";
import TransferRequest from "./layouts/TransferRequest";
import Users from "./layouts/Users";
import Privacypolicy from "./layouts/homepage/PrivacyPolicy/Privacypolicy";
import Termsofservices from "./layouts/homepage/Terms/Termsofservices";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    key: "sidebar",
    route: "/signin",
    component: <Signin />,
  },
  {
    key: "sidebar",
    route: "/deleteaccount",
    component: <Deleteaccount />,
  },
  {
    key: "sidebar",
    route: "/TermsConditions",
    component: <Termsofservices />,
  },
  {
    key: "sidebar",
    route: "/privacypolicy",
    component: <Privacypolicy />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "NotapproveStreamer",
    key: "notapproveStreamer",
    route: "/NotapproveStreamer",
    component: <NotApprovedStremer />,
  },
  {
    type: "collapse",
    name: "Advertisement",
    key: "advertisement",
    route: "/Advertisement",
    component: <Advertisement />,
  },
  {
    type: "collapse",
    name: "setting",
    key: "setting",
    route: "/setting",
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "Advertisement",
    key: "advertisement",
    route: "/add-addvertisement",
    component: <Addadvertisement />,
  },
  {
    type: "collapse",
    name: "Advertisement",
    key: "advertisement",
    route: "/Edit-addvertisement",
    component: <EditAdvertisement />,
  },
  {
    type: "collapse",
    name: "Transferrequest",
    key: "transferrequest",
    route: "/Transferrequest",
    component: <TransferRequest />,
  },
  {
    type: "collapse",
    name: "TransferRefund",
    key: "transferRefund",
    route: "/TransferRefund",
    component: <TransferRefund />,
  },
  {
    type: "collapse",
    name: "Allplans",
    key: "allplans",
    route: "/Allplans",
    component: <Allplans />,
  },
  {
    type: "collapse",
    name: "Allplans",
    key: "allplans",
    route: "/Add-plan",
    component: <Addplans />,
  },
  {
    type: "collapse",
    name: "Allplans",
    key: "allplans",
    route: "/Edit-plan",
    component: <Editplans />,
  },
  {
    type: "collapse",
    name: "Alltransactions",
    key: "alltransactions",
    route: "/Alltransactions",
    component: <Alltransactions />,
  },
  {
    type: "collapse",
    name: "termsandcondition",
    key: "termsandcondition",
    route: "/termsandcondition",
    component: <Terms />,
  },
  {
    type: "collapse",
    name: "about",
    key: "about",
    route: "/about",
    component: <About />,
  },
  {
    type: "collapse",
    name: "contact",
    key: "contact",
    route: "/contact",
    component: <Contact />,
  },
  {
    type: "collapse",
    name: "Events",
    key: "Events",
    route: "/Events",
    component: <Events />,
  },
];

export default routes;
