import React, { useEffect, useState } from "react";
import Footer from "../../Footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const EditAdvertisement = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [img, setImage] = useState("");
  const [discription, setDiscription] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const navigate = useNavigate();

  const handleimage = async (files, data_image) => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const maxFileSize = 1024 * 1024; // 1 MB
    const file = files[0];

    if (!files || !files.length) {
      alert("Please select a file");
      return;
    }

    const extension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(extension)) {
      alert("Only jpg, jpeg, and png files are allowed");
      return;
    }

    if (file.size > maxFileSize) {
      alert("File size must be less than 1 MB");
      return;
    }

    let form = new FormData();
    form.append(data_image, file);

    let result = await fetch("/muzy/image", {
      method: "post",
      body: form,
    });

    result = await result.json();
    setImage(result?.path?.location);
  };

  useEffect(() => {
    const getplan = async () => {
      try {
        const res = await axios.get(
          `/muzy/adminUsers/get-single-ads?adId=${localStorage.getItem(
            "addid"
          )}`,
          {
            headers: {
              Authorization: currentUser[0]?.token,
            },
          }
        );
        setTitle(res.data.data.title);
        setImage(res.data.data.bannerImage);
        setDiscription(res.data.data.description);
        setStartdate(res.data.data.startDate);
        setEnddate(res.data.data.endDate);
      } catch (error) {
        console.log(error);
      }
    };

    getplan();
  }, [currentUser]);

  const AddPlans = async () => {
    try {
      if (!title || !img || !discription || !startdate || !enddate) {
        toast.error("Please provide all required fields.");
        return;
      }

      await axios.patch(
        `/muzy/adminUsers/edit-ads?adId=${localStorage.getItem("addid")}`,
        {
          title: title,
          description: discription,
          bannerImage: img,
          isActive: true,
          startDate: startdate,
          endDate: enddate,
        },
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      navigate(-1);
      localStorage.removeItem("addid");
      toast.success("Advertisement Edited successfully!");
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="mb-0">Edit Advertisement</h4>
                  <Link to="/Advertisement" className="btn btn-sm btn-primary">
                    Go Back
                  </Link>
                </div>
                <div className="container-fluid">
                  <div className="row h-100 align-items-center justify-content-center">
                    <div
                      className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4"
                      style={{ width: "50%" }}
                    >
                      <div className="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                        <div className="mb-3">
                          <input
                            type="file"
                            className="form-control text-black"
                            name="profileImage"
                            onChange={(e) =>
                              handleimage(e.target.files, e.target.name)
                            }
                          />
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            required
                            className="form-control text-white"
                            id="floatingText"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <label htmlFor="floatingText">Title</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control text-white"
                            value={discription}
                            onChange={(e) => setDiscription(e.target.value)}
                          />
                          <label htmlFor="floatingInput">Discription</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control text-white"
                            type="date"
                            value={
                              startdate
                                ? new Date(startdate)
                                    .toISOString()
                                    .substr(0, 10)
                                : ""
                            }
                            onChange={(e) => setStartdate(e.target.value)}
                          />
                          <label htmlFor="floatingInput">Start Date</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control text-white"
                            type="date"
                            value={
                              enddate
                                ? new Date(enddate).toISOString().substr(0, 10)
                                : ""
                            }
                            onChange={(e) => setEnddate(e.target.value)}
                          />
                          <label htmlFor="floatingInput">End Date</label>
                        </div>
                        <button
                          onClick={AddPlans}
                          type="submit"
                          className="btn btn-primary py-3 w-100 mb-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default EditAdvertisement;
