import React from "react";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/Slices/Userslice";
import { Link, useNavigate } from "react-router-dom";
import admin from "../../assets/admin.png";

const Navbar = ({ set, setset }) => {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleButtonClick = () => {
    setset(!set);
  };

  const Logout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/signin");
  };
  return (
    <div>
      {set ? "" : <Sidebar />}
      <nav className="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
        <a href="index.html" className="navbar-brand d-flex d-lg-none me-4">
          <h2 className="text-primary mb-0">
            <i className="fa fa-user-edit" />
          </h2>
        </a>
        <div
          style={{ cursor: "pointer" }}
          className="sidebar-toggler flex-shrink-0"
          onClick={handleButtonClick}
        >
          <i className="fa fa-bars" />
        </div>

        <div className="navbar-nav align-items-center ms-auto">
          <div className="nav-item dropdown">
            <div
              style={{ cursor: "pointer" }}
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <img
                className="rounded-circle me-lg-2"
                src={admin}
                alt=""
                style={{ width: 40, height: 40 }}
              />
              <span className="d-none d-lg-inline-flex">
                {currentUser[0]?.name}
              </span>
            </div>
            <div className="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
              <Link
                to="/setting"
                className="dropdown-item"
                style={{ cursor: "pointer" }}
              >
                Settings
              </Link>
              <div
                onClick={Logout}
                className="dropdown-item"
                style={{ cursor: "pointer" }}
              >
                Log Out
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
