import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer";

const Privacypolicy = () => {
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md navbar-dark bg-dark-secondary">
          <div className="container">
            <img
              src="/assests/img/Logo.svg"
              alt="logo"
              style={{ height: "40px", width: "200px" }}
            />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-start navastyel"
              id="navbarCollapse"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link font-weight-100" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link font-weight-100"
                    to="/TermsConditions"
                  >
                    Terms & Condition
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link font-weight-100" to="/privacypolicy">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <section id="terms-of-service">
        <div className="card2">
          <h1 className="primary-heading">Privacy Policy</h1>
          <div>
            <div
              style={{
                margin: "67px",
              }}
            >
              <p>
                This privacy policy applies to the Muzy app (hereby referred to
                as "Application") for mobile devices that was created by Coblea
                LLC (hereby referred to as "Service Provider") as a Commercial
                service. This service is intended for use "AS IS".
              </p>
              <br></br>
              <h5>Information Collection and Use</h5>
              <p>
                The Application collects information when you download and use
                it. This information may include information such as
              </p>
              <br></br>
              <p>Your device's Internet Protocol address (e.g. IP address)</p>
              <p>
                The pages of the Application that you visit, the time and date
                of your visit, the time spent on those pages
              </p>
              <p>The time spent on the Application</p>
              <p>The operating system you use on your mobile device</p>
              <br></br>
              <p>
                The Application does not gather precise information about the
                location of your mobile device.
              </p>
              <br></br>
              <p>
                The Service Provider may use the information you provided to
                contact you from time to time to provide you with important
                information, required notices and marketing promotions.
              </p>
              <p>
                For a better experience, while using the Application, the
                Service Provider may require you to provide us with certain
                personally identifiable information, including but not limited
                to Email, Name, . The information that the Service Provider
                request will be retained by them and used as described in this
                privacy policy.
              </p>
              <br></br>
              <h5>Third Party Access</h5>
              <p>
                Only aggregated, anonymized data is periodically transmitted to
                external services to aid the Service Provider in improving the
                Application and their service. The Service Provider may share
                your information with third parties in the ways that are
                described in this privacy statement.
              </p>
              <br></br>
              <p>
                Please note that the Application utilizes third-party services
                that have their own Privacy Policy about handling data. Below
                are the links to the Privacy Policy of the third-party service
                providers used by the Application:
              </p>
              <p>Google Play Services</p>
              <p>Google Analytics for Firebase</p>
              <p>Facebook</p>
              <br></br>
              <h5>
                The Service Provider may disclose User Provided and
                Automatically Collected Information:
              </h5>
              <br></br>
              <p>
                as required by law, such as to comply with a subpoena, or
                similar legal process;
              </p>
              <p>
                when they believe in good faith that disclosure is necessary to
                protect their rights, protect your safety or the safety of
                others, investigate fraud, or respond to a government request;
                with their trusted services providers who work on their behalf,
                do not have an independent use of the information we disclose to
                them, and have agreed to adhere to the rules set forth in this
                privacy statement.
              </p>
              <br></br>
              <h5>Opt-Out Rights</h5>
              <p>
                You can stop all collection of information by the Application
                easily by uninstalling it. You may use the standard uninstall
                processes as may be available as part of your mobile device or
                via the mobile application marketplace or network.
              </p>
              <br></br>
              <h5>Data Retention Policy</h5>
              <p>
                The Service Provider will retain User Provided data for as long
                as you use the Application and for a reasonable time thereafter.
                If you'd like them to delete User Provided Data that you have
                provided via the Application, please contact them at
                info@muzy.live and they will respond in a reasonable time.
              </p>
              <br></br>
              <h5>Children</h5>
              <p>
                The Service Provider does not use the Application to knowingly
                solicit data from or market to children under the age of 13.
              </p>
              <br></br>
              <p>
                The Application does not address anyone under the age of 13. The
                Service Provider does not knowingly collect personally
                identifiable information from children under 13 years of age. In
                the case the Service Provider discover that a child under 13 has
                provided personal information, the Service Provider will
                immediately delete this from their servers. If you are a parent
                or guardian and you are aware that your child has provided us
                with personal information, please contact the Service Provider
                (info@muzy.live) so that they will be able to take the necessary
                actions.
              </p>
              <br></br>
              <h5>Security</h5>
              <p>
                The Service Provider is concerned about safeguarding the
                confidentiality of your information. The Service Provider
                provides physical, electronic, and procedural safeguards to
                protect information the Service Provider processes and
                maintains.
              </p>
              <br></br>
              <h5>Changes</h5>Changes
              <p>
                This Privacy Policy may be updated from time to time for any
                reason. The Service Provider will notify you of any changes to
                the Privacy Policy by updating this page with the new Privacy
                Policy. You are advised to consult this Privacy Policy regularly
                for any changes, as continued use is deemed approval of all
                changes.
              </p>
              <br></br>
              <h4>This privacy policy is effective as of 2024-04-01</h4>
              <br></br>
              <h5>Your Consent</h5>
              <p>
                By using the Application, you are consenting to the processing
                of your information as set forth in this Privacy Policy now and
                as amended by us.
              </p>
              <br></br>
              <h5>Contact Us</h5>
              <p>
                If you have any questions regarding privacy while using the
                Application, or have questions about the practices, please
                contact the Service Provider via email at info@muzy.live.
              </p>
              <br></br>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacypolicy;
