import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Terms = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState({});
  const [Contenttitle, setContenttitle] = useState({});
  const { currentUser } = useSelector((state) => state.user);
  // const [loading, setloading] = useState(false);
  const getAllusers = async () => {
    // setloading(true);
    try {
      const res = await axios.get(`/muzy/privacy/companyGetPrivacy`, {
        headers: {
          Authorization: currentUser[0]?.token,
        },
      });

      // setloading(false);
      setContent(res.data.getData[0].privacyContent);
      setContenttitle(res.data.getData[0].title);
    } catch (error) {
      console.log(error);
      // setloading(false);
    }
  };

  useEffect(() => {
    getAllusers();
  }, []);

  const handleChange = (value) => {
    setContent(value);
  };

  const UpdateTermsAndcondition = async () => {
    // setloading(true);
    try {
      await axios.put(
        `/muzy/privacy/companyUpdatePrivacy?id=651e9505161d35ac92a69f75`,
        {
          title: Contenttitle,
          privacyContent: content,
        },
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      // setloading(false);

      toast.success("Terms And Condition Updated successfully!");
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again later.");
      // setloading(false);
    }
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4 h-100">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="mb-0">Terms And Condition</h4>
                </div>
                <div className="row">
                  <div className="form-floating mb-3  col-md-12">
                    <input
                      className="form-control text-white"
                      inputMode="numeric"
                      value={Contenttitle}
                      onChange={(e) => setContenttitle(e.target.value)}
                      required
                      id="floatingInput check"
                      type="text"
                      autoComplete="off"
                    />
                    <label
                      htmlFor="floatingInput "
                      className="mx-2 h6 text-white"
                    >
                      Terms And Condition
                    </label>
                  </div>
                </div>
                <div style={{ height: "70vh" }}>
                  <ReactQuill
                    style={{ height: "60vh", color: "white" }}
                    value={content}
                    onChange={handleChange}
                    scrollingContainer="#scrolling-container"
                    theme="snow"
                  />
                  <button
                    className=" btn btn-dark"
                    onClick={UpdateTermsAndcondition}
                    style={{ marginTop: "60px" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate("/signin")
      )}
    </>
  );
};

export default Terms;
