import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../loader/loader";
import Empty from "../loader/empty";
import { toast } from "react-toastify";

const TransferRequest = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(false);
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 10;
  const shouldShowPagination = count > cardsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getAllusers = async () => {
    setloading(true);
    try {
      const res = await axios.get(
        `/muzy/adminStreamer/get-request?page=${currentPage}&limit=10`,
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      setUsers(res.data.data);
      setCount(res?.data?.totalDataCount);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  useEffect(() => {
    getAllusers();
  }, [currentPage]);

  const accseptreject = async ( id, status) => {
    setloading(true);

    try {
      await axios.patch(
        `/muzy/adminStreamer/update-request?streameer_id=${id}`,
        { status: status },
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      setloading(false);
      toast.success("status Updated Successfully");
      getAllusers();
    } catch (error) {
      toast.error(error.message);
      setloading(false);
    }
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="mb-0">Transfer Requests</h4>
                </div>
                <div className="table-responsive " style={{ height: "60vh" }}>
                  {loading ? (
                    <div
                      className="text-center d-flex justify-content-center align-items-center"
                      style={{
                        height: "inherit",
                      }}
                    >
                      <div className="">
                        <Loader />
                      </div>
                    </div>
                  ) : users?.length > 0 ? (
                    <table className="table text-start text-center table-bordered table-hover mb-0 position-relative">
                      <thead>
                        <tr className="text-white text-capitalize">
                          <th scope="col">#</th>
                          <th scope="col">Admin</th>
                          <th scope="col">Streamer</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                          <th scope="col">Created at</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {users?.map((user, index) => (
                          <tr
                            key={index}
                            className="text-white text-capitalize "
                          >
                            <td>{index + 1}</td>
                            <td>
                              <div
                                className="d-flex justify-content-center"
                                style={{ flexDirection: "column" }}
                              >
                                <h6>{user?.admin_id?.email}</h6>
                                <h4>{user?.admin_id?.Name}</h4>
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-flex justify-content-center "
                                style={{ flexDirection: "column" }}
                              >
                                <h6>{user?.streameer_id?.email}</h6>
                                <h4>{user?.streameer_id?.name}</h4>
                              </div>
                            </td>
                            <td>{user?.amount} $</td>
                            <td>
                              <div
                                style={{
                                  color: "white",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  display: "inline-block",
                                  textShadow: "2px 2px #ab918d",
                                  backgroundColor:
                                    user?.status === "0"
                                      ? "#c4c406"
                                      : user?.status === "1"
                                      ? "#3eca3e"
                                      : "red",
                                }}
                              >
                                {user?.status === "1"
                                  ? "Accepted"
                                  : user?.status === "2"
                                  ? "Rejected"
                                  : "Pending"}
                              </div>
                            </td>
                            <td>
                              {new Date(user?.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              {user?.status === "0" ? (
                                <>
                                  <div
                                    className="btn btn-sm"
                                    onClick={() =>
                                      accseptreject(user._id, 1)
                                    }
                                    style={{
                                      backgroundColor: "#3eca3e",
                                      color: "white",
                                    }}
                                  >
                                    Accept
                                  </div>
                                  <div
                                    style={{ marginLeft: "20px" }}
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                      accseptreject(user._id, 2)
                                    }
                                  >
                                    Reject
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {users.length > 0 && shouldShowPagination && (
              <div
                className="d-flex justify-content-end mx-4 my-4"
                style={{ marginLeft: "20px" }}
              >
                {currentPage !== 1 && (
                  <button
                    className="pagination-button"
                    onClick={() => paginate(currentPage - 1)}
                    style={{ borderRadius: "20px" }}
                  >
                    Previous
                  </button>
                )}
                {Array.from(
                  { length: Math.ceil(count / cardsPerPage) },
                  (_, i) => {
                    if (
                      i === 0 ||
                      i === currentPage - 1 ||
                      i === currentPage - 2 ||
                      i === currentPage ||
                      i === currentPage + 1 ||
                      i === Math.ceil(count / cardsPerPage) - 1
                    ) {
                      return (
                        <button
                          key={i}
                          className={`pagination-button rounded-circle ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </button>
                      );
                    } else if (
                      (i === currentPage - 3 && currentPage > 3) ||
                      (i === currentPage + 2 &&
                        currentPage < Math.ceil(count / cardsPerPage) - 2)
                    ) {
                      return <span key={i}>...</span>;
                    }
                    return null;
                  }
                )}
                {currentPage !== Math.ceil(count / cardsPerPage) && (
                  <button
                    className="pagination-button"
                    onClick={() => paginate(currentPage + 1)}
                    style={{ borderRadius: "20px" }}
                  >
                    Next
                  </button>
                )}
              </div>
            )}
            <Footer />
          </div>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default TransferRequest;
