import React, { useEffect, useState } from "react";
import Footer from "../../Footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Editplans = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [planId, setPlanId] = useState("");
  const [planname, setPlanname] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  // const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getplan = async () => {
      try {
        const res = await axios.get(
          `/muzy/adminSubscription/plan/getPlanById?planId=${localStorage.getItem(
            "planid"
          )}`,
          {
            headers: {
              Authorization: currentUser[0]?.token,
            },
          }
        );
        setPlanname(res.data.plan.name);
        setDuration(res.data.plan.duration);
        setPrice(res.data.plan.price);
        setPlanId(res.data.plan.ProductId);
      } catch (error) {
        console.log(error);
      }
    };

    getplan();
  }, [currentUser]);

  const AddPlans = async () => {
    // setloading(true);
    try {
      if (!planname || !duration || !price) {
        toast.error("Please provide all required fields.");
        return;
      }

      await axios.put(
        `/muzy/adminSubscription/plan/updatePlan?planId=${localStorage.getItem(
          "planid"
        )}`,
        {
          name: planname,
          duration: duration,
          price: price,
        },
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      // setloading(false);
      navigate(-1);
      localStorage.removeItem("planid");
      toast.success("Plan Edited successfully!");
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again later.");
      // setloading(false);
    }
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="mb-0">Edit Plans</h4>
                  <Link to="/Allplans" className="btn btn-sm btn-primary">
                    Go Back
                  </Link>
                </div>
                <div className="container-fluid">
                  <div className="row h-100 align-items-center justify-content-center">
                    <div
                      className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4"
                      style={{ width: "50%" }}
                    >
                      <div className="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                        <div className="form-floating mb-3">
                          <input
                            disabled
                            value={planId}
                            type="text"
                            className="form-control text-white"
                            id="floatingText"
                            onChange={(e) => setPlanId(e.target.value)}
                          />
                          <label htmlFor="floatingText">Product Id</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            value={planname}
                            type="text"
                            className="form-control text-white"
                            id="floatingText"
                            onChange={(e) => setPlanname(e.target.value)}
                          />
                          <label htmlFor="floatingText">Plan Name</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control text-white"
                            onChange={(e) =>
                              setDuration(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1")
                              )
                            }
                            inputMode="numeric"
                            id="floatingInput check"
                            type="text"
                            maxlength="4"
                            autoComplete="off"
                            value={duration}
                          />
                          <label htmlFor="floatingInput">Duration</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control text-white"
                            inputMode="numeric"
                            id="floatingInput check"
                            type="text"
                            maxlength="6"
                            autoComplete="off"
                            value={price}
                            onChange={(e) =>
                              setPrice(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1")
                              )
                            }
                          />
                          <label htmlFor="floatingInput">Price</label>
                        </div>
                        <button
                          onClick={AddPlans}
                          type="submit"
                          className="btn btn-primary py-3 w-100 mb-4"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default Editplans;
