import React, { useState, useEffect, useCallback } from "react";
import Footer from "../../Footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const Addplans = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [planname, setPlanname] = useState("");
  const [planId, setPlanId] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // const validatePlanIdFormat = (id) => {
  //   const regex = /^[a-z0-9][a-z0-9._]*$/;
  //   return regex.test(id);
  // };

  // function validatePlanIdFormat(planId) {
  //   const regex = /^[a-z0-9][a-z0-9_.]*$/;
  //   // const includesNumber = /\d/;
  //   // const includesUnderscore = /_/;

  //   return (
  //     regex.test(planId) &&
  //     // includesNumber.test(planId) &&
  //     // includesUnderscore.test(planId) &&
  //     planId.length >= 5 &&
  //     planId.length <= 50
  //   );
  // }

  const checkProductIdExists = useCallback(
    debounce(async (id) => {
      try {
        const response = await axios.get(
          `/muzy/adminSubscription/plan/planFind/${id}`,
          {
            headers: {
              Authorization: currentUser[0]?.token,
            },
          }
        );
        return response.data.status;
      } catch (error) {
        console.error("Error checking Product ID:", error);
        return false;
      }
    }, 500),
    [currentUser]
  );

  useEffect(() => {
    const validateProductId = async () => {
      const newErrors = {};
      if (planId) {
        const exists = await checkProductIdExists(planId);
        if (exists === false) {
          newErrors.planId =
            "Product Id already exists. Please choose another.";
        }
      }
      setErrors((prevErrors) => ({ ...prevErrors, planId: newErrors.planId }));
    };

    validateProductId();
  }, [planId, checkProductIdExists]);

  const handleAddPlans = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!planname) newErrors.planname = "Please provide a plan name.";
    if (!planId) {
      newErrors.planId = "Please provide a Product Id.";
    }
    if (!duration) newErrors.duration = "Please provide a duration.";
    if (!price) newErrors.price = "Please provide a price.";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    try {
      await axios.post(
        "/muzy/adminSubscription/plan/create",
        {
          ProductId: planId,
          name: planname,
          duration: duration,
          price: price,
        },
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );

      navigate(-1);
    } catch (error) {
      console.log(error);
      setErrors({ form: "An error occurred. Please try again later." });
    }
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb=4">
                  <h4 className="mb-0">Add Plans</h4>
                  <Link to="/Allplans" className="btn btn-sm btn-primary">
                    Go Back
                  </Link>
                </div>
                <div className="container-fluid">
                  <div className="row h-100 align-items-center justify-content-center">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 w-50 text-white">
                      <form onSubmit={handleAddPlans}>
                        <div className="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                          <div className="form-floating mb-3">
                            <input
                              value={planId}
                              type="text"
                              required
                              className="form-control text-white"
                              id="floatingText"
                              onChange={(e) => {
                                setPlanId(e.target.value);
                                if (!e.target.value) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    planId: "",
                                  }));
                                }
                              }}
                            />
                            <label htmlFor="floatingText">Product Id</label>
                            {errors.planId && (
                              <div className="text-danger">{errors.planId}</div>
                            )}
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              value={planname}
                              type="text"
                              required
                              className="form-control text-white"
                              id="floatingText"
                              onChange={(e) => setPlanname(e.target.value)}
                            />
                            <label htmlFor="floatingText">Plan Name</label>
                            {errors.planname && (
                              <div className="text-danger">
                                {errors.planname}
                              </div>
                            )}
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              className="form-control text-white"
                              onChange={(e) =>
                                setDuration(
                                  e.target.value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*?)\..*/g, "$1")
                                )
                              }
                              inputMode="numeric"
                              required
                              id="floatingInput"
                              type="text"
                              maxLength="4"
                              autoComplete="off"
                              value={duration}
                            />
                            <label htmlFor="floatingInput">Duration</label>
                            {errors.duration && (
                              <div className="text-danger">
                                {errors.duration}
                              </div>
                            )}
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              className="form-control text-white"
                              inputMode="numeric"
                              id="floatingInput"
                              type="text"
                              required
                              maxLength="6"
                              autoComplete="off"
                              value={price}
                              onChange={(e) =>
                                setPrice(
                                  e.target.value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*?)\..*/g, "$1")
                                )
                              }
                            />
                            <label htmlFor="floatingInput">Price</label>
                            {errors.price && (
                              <div className="text-danger">{errors.price}</div>
                            )}
                          </div>
                          {errors.form && (
                            <div className="text-danger">{errors.form}</div>
                          )}
                          <button
                            type="submit"
                            className="btn btn-primary py-3 w-100 mb-4"
                          >
                            Add New Plan
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}
    </>
  );
};

export default Addplans;
