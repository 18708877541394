import { configureStore, combineReducers } from "@reduxjs/toolkit";

import userReducer from "./Slices/Userslice";
import itemReducer from "./Slices/plansslice";

const rootReducer = combineReducers({
  user: userReducer,
  plan: itemReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
