import React from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../redux/Slices/Userslice";
// import Logo from "../../assets/Logo.svg";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Logout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/signin");
  };

  return (
    <>
      <div className="sidebar pe-4 pb-3 ">
        <nav className="navbar bg-secondary navbar-dark">
          <Link to="/" className="navbar-brand mx-4 mb-3">
            <img src="/assests/Logo.svg" alt="logo" width="100" height="100" />
          </Link>
          <div className="navbar-nav w-100 my-3">
            <NavLink
              to="/dashboard"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "-50px" }}
            >
              <i className="fa fa-tachometer-alt me-2" />
              Dashboard
            </NavLink>
            <NavLink
              to="/users"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="fa fa-th me-2" />
              Users
            </NavLink>
            <NavLink
              to="/NotapproveStreamer"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="bi bi-cast me-2" />
              Streamer Request
            </NavLink>
            <NavLink
              to="/Advertisement"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="bi bi-patch-plus-fill me-2" />
              Advertisement
            </NavLink>
            <NavLink
              to="/Allplans"
              className="nav-item nav-link text-truncate"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="bi bi-flower2 me-2" />
              Subscription Plans
            </NavLink>
            <NavLink
              to="/Transferrequest"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="bi bi-wallet me-2" />
              Transfer Request
            </NavLink>
            <NavLink
              to="/TransferRefund"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="fa fa-undo me-2" />
              Transfer Refund
            </NavLink>
            <NavLink
              to="/Alltransactions"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="bi bi-cash-stack me-2" />
              Transactions
            </NavLink>
            <NavLink
              to="/Events"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="bi bi-calendar-event-fill me-2" />
              Events
            </NavLink>
            <NavLink
              to="/setting"
              className="nav-item nav-link"
              activeclassname="active"
              style={{ marginTop: "15px" }}
            >
              <i className="bi bi-gear me-2" />
              Settings
            </NavLink>
            <NavLink
              to="/termsandcondition"
              className="nav-item nav-link  text-truncate"
              style={{ marginTop: "15px" }}
            >
              <i className="bi bi-journal me-2"></i>
              Terms & Condition
            </NavLink>
            <div
              onClick={Logout}
              className="nav-item nav-link  text-truncate"
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-door-closed-fill me-2"></i>
              Log Out
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
