// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 250px;
  height: 150px;
  position: relative;
  animation: rotation 0.75s linear infinite;
  border-radius: 100em;
}

.path {
  stroke-dasharray: 100;
  stroke-dashoffset: 20;
  stroke-linecap: round;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/layouts/loader/loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.spinner {\n  width: 250px;\n  height: 150px;\n  position: relative;\n  animation: rotation 0.75s linear infinite;\n  border-radius: 100em;\n}\n\n.path {\n  stroke-dasharray: 100;\n  stroke-dashoffset: 20;\n  stroke-linecap: round;\n}\n\n@keyframes rotation {\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
