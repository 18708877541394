import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Dashboard = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [countt, setCount] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (!currentUser[0]?.token) navigate(`/signin`);

    const getAllusers = async () => {
      try {
        const res = await axios.get(`/muzy/adminStreamer/getAllCount`, {
          headers: {
            Authorization: currentUser[0].token,
          },
        });

        setCount(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getAllusers();
  }, [currentUser]);

  return (
    <>
      {currentUser[0]?.token ? (
        <div className="">
          {/* Sale & Revenue Start */}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-6 col-xl-3">
                <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-chart-line fa-3x text-primary" />
                  <div className="ms-3">
                    <p className="mb-2">Total Users</p>
                    <h6 className="mb-0">{countt?.userCount}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-chart-bar fa-3x text-primary" />
                  <div className="ms-3">
                    <p className="mb-2">Total Premium Users</p>
                    <h6 className="mb-0">{countt?.userPremiumCount}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-chart-area fa-3x text-primary" />
                  <div className="ms-3">
                    <p className="mb-2">Total Streamers</p>
                    <h6 className="mb-0">{countt?.streamingCount}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-chart-pie fa-3x text-primary" />
                  <div className="ms-3">
                    <p className="mb-2">Total Revenue</p>
                    <h6 className="mb-0">$ {countt?.transactionCount}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        navigate("/signin")
      )}
    </>
  );
};

export default Dashboard;
