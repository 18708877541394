import React from "react";

const Footer = ({ set, setset }) => {
  return (
    <div className=" container-fluid pt-4 px-4 text-capitalize">
      <div className="bg-secondary rounded-top p-4">
        <div className="d-flex justify-content-between">
          <div className="">
            © <a href="/">Muzy</a>, All Right Reserved.
          </div>
          <div className="">
            Designed By <a rel="noreferrer" target="_blank" href="https://hamiltonkw.com/en/">hamilton</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
