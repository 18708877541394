import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../loader/loader";
import Empty from "../loader/empty";
import Modal from "react-modal";

const Alltransactions = () => {
  const today = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30))
    .toISOString()
    .split("T")[0];
  const { currentUser } = useSelector((state) => state.user);
  const [count, setCount] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setstatus] = useState("");
  const [startDate, setstartDate] = useState(thirtyDaysAgo);
  const [endDate, setendDate] = useState(today);
  const [showModal, setShowModal] = useState(false);
  const cardsPerPage = 10;
  const shouldShowPagination = count > cardsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const getAllusers = async () => {
    setloading(true);
    try {
      const res = await axios.get(
        `/muzy/adminStreamer/getAllTransaction?status=${status}&startDate=${startDate}&endDate=${endDate}&type=&page=${currentPage}`,
        {
          headers: {
            Authorization: currentUser[0]?.token,
          },
        }
      );
      setloading(false);

      setUsers(res.data.transactionData);
      setCount(res.data?.totalCount);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    getAllusers();
  }, [currentUser, currentPage]);

  const handleSearch = () => {
    getAllusers();
  };

  return (
    <>
      {currentUser[0]?.token ? (
        <div>
          <div className="">
            <div className="container-fluid pt-4 px-4">
              <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="mb-0">All Transcations</h4>
                </div>
                {/* style={{ height: "75vh" }} */}
                <div className="table-responsive ">
                  <div className="row mx-1">
                    <div className="form-floating mb-3 col-md-3 ">
                      <select
                        className="form-select text-white"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option value=""> Select Status</option>
                        <option value="success">Success</option>
                        <option value="failure">Failure</option>
                        <option value="pending">Pending</option>
                        <option value="refund">Refund</option>
                      </select>
                      <label htmlFor="floatingSelect" style={{left:"10px"}}>Status</label>
                    </div>

                    <div className="form-floating mb-3  col-md-3">
                      <input
                        className="form-control text-white"
                        inputMode="numeric"
                        required
                        id="floatingInput check"
                        type="date"
                        placeholder="name, phone"
                        autoComplete="off"
                        value={startDate}
                        onChange={(e) => setstartDate(e.target.value)}
                      />
                      <label htmlFor="floatingInput" style={{left:"10px"}}>Start Date</label>
                    </div>
                    <div className="form-floating mb-3  col-md-3">
                      <input
                        className="form-control text-white"
                        inputMode="numeric"
                        required
                        id="floatingInput check"
                        type="date"
                        autoComplete="off"
                        value={endDate}
                        onChange={(e) => setendDate(e.target.value)}
                      />
                      <label htmlFor="floatingInput" style={{left:"10px"}}>End Date</label>
                    </div>
                    <div className="col-md-1  mt-2">
                      <button
                        className="pagination-button "
                        style={{ borderRadius: "20px" }}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {loading ? (
                    <div
                      className="text-center d-flex justify-content-center align-items-center"
                      style={{
                        height: "inherit",
                      }}
                    >
                      <div className="">
                        <Loader />
                      </div>
                    </div>
                  ) : users?.length > 0 ? (
                    <table className="table text-start text-center table-bordered table-hover mb-0 position-relative">
                      <thead>
                        <tr className="text-white text-capitalize">
                          <th scope="col">#</th>
                          <th scope="col">Profile</th>
                          <th scope="col">User</th>
                          <th scope="col">Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Plan</th>
                          <th scope="col">Status</th>
                          <th scope="col">Price</th>
                          <th scope="col">Transcation Date </th>
                        </tr>
                      </thead>

                      <tbody>
                        {users?.map((user, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="d-flex justify-content-center">
                              {user?.userId?.profileImage ? (
                                <img
                                  className="rounded-circle flex-shrink-0"
                                  src={user?.userId?.profileImage}
                                  alt="dp"
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "cover",
                                    backgroundColor: "#ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%",
                                    position: "relative",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "white",
                                      fontSize: "24px",
                                    }}
                                  >
                                    <i className="fas fa-user"></i>
                                  </span>
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      color: "red",
                                      fontSize: "24px",
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              <div>
                                <h5 className="text-capitalize">
                                  {user?.userId?.userName}
                                </h5>
                                <h6>{user?.userId?.email}</h6>
                              </div>
                            </td>
                            <td>
                              <div>
                                <h5 className="text-capitalize">
                                  {user?.userId?.name}
                                </h5>
                              </div>
                            </td>
                            <td>{user?.type}</td>
                            <td>
                              {user?.planId?.name ? user?.planId?.name : "--"}
                            </td>
                            <td
                              className="text-capitalize"
                              style={{ color: "#1870f9" }}
                              onClick={() => setShowModal(true)}
                            >
                              {user?.status}
                            </td>
                            <td>{user?.amount}</td>
                            <td>
                              {new Date(user.createdAt).toLocaleDateString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {users.length > 0 && shouldShowPagination && (
              <div
                className="d-flex justify-content-end mx-4 my-4"
                style={{ marginLeft: "20px" }}
              >
                {currentPage !== 1 && (
                  <button
                    className="pagination-button"
                    onClick={() => paginate(currentPage - 1)}
                    style={{ borderRadius: "20px" }}
                  >
                    Previous
                  </button>
                )}
                {Array.from(
                  { length: Math.ceil(count / cardsPerPage) },
                  (_, i) => {
                    if (
                      i === 0 ||
                      i === currentPage - 1 ||
                      i === currentPage - 2 ||
                      i === currentPage ||
                      i === currentPage + 1 ||
                      i === Math.ceil(count / cardsPerPage) - 1
                    ) {
                      return (
                        <button
                          key={i}
                          className={`pagination-button rounded-circle ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </button>
                      );
                    } else if (
                      (i === currentPage - 3 && currentPage > 3) ||
                      (i === currentPage + 2 &&
                        currentPage < Math.ceil(count / cardsPerPage) - 2)
                    ) {
                      return <span key={i}>...</span>;
                    }
                    return null;
                  }
                )}
                {currentPage !== Math.ceil(count / cardsPerPage) && (
                  <button
                    className="pagination-button"
                    onClick={() => paginate(currentPage + 1)}
                    style={{ borderRadius: "20px" }}
                  >
                    Next
                  </button>
                )}
              </div>
            )}

            <Footer />
          </div>
        </div>
      ) : (
        (window.location.href = "/signin")
      )}

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgb(28 25 25)",
            zIndex: 1000,
            backdropFilter: "blur(5px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "relative",
            maxWidth: "fit-content",
            // width: "90%",
            background: "rgb(28 25 25)",
            borderRadius: "12px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            padding: "30px",
            zIndex: 1001,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <table className="table text-start text-center table-bordered table-hover mb-0 position-relative">
          <thead>
            <tr className="text-white text-capitalize">
              <th scope="col">#</th>
              <th scope="col">Profile</th>
              <th scope="col">User</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Plan</th>
              <th scope="col">Status</th>
              <th scope="col">Price</th>
              <th scope="col">Transcation Date </th>
            </tr>
          </thead>

          <tbody>
            {users?.map((user, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td className="d-flex justify-content-center">
                  {user?.userId?.profileImage ? (
                    <img
                      className="rounded-circle flex-shrink-0"
                      src={user?.userId?.profileImage}
                      alt="dp"
                      style={{
                        width: "60px",
                        height: "60px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "60px",
                        height: "60px",
                        objectFit: "cover",
                        backgroundColor: "#ccc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          fontSize: "24px",
                        }}
                      >
                        <i className="fas fa-user"></i>
                      </span>
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "red",
                          fontSize: "24px",
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </span>
                    </div>
                  )}
                </td>
                <td>
                  <div>
                    <h5 className="text-capitalize">
                      {user?.userId?.userName}
                    </h5>
                    <h6>{user?.userId?.email}</h6>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className="text-capitalize">{user?.userId?.name}</h5>
                  </div>
                </td>
                <td>{user?.type}</td>
                <td>{user?.planId?.name ? user?.planId?.name : "--"}</td>
                <td
                  className="text-capitalize"
                  onClick={() => setShowModal(true)}
                >
                  {user?.status}
                </td>
                <td>{user?.amount}</td>
                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default Alltransactions;
