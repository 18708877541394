import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  loginStart,
  loginSuccess,
  loginFailure,
} from "../../redux/Slices/Userslice";
import { toast } from "react-toastify";
// import Logo from "../../assets/Logo.svg";

const Signin = () => {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const Login = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      const res = await axios.post("/muzy/admin/adminLogin", {
        email,
        password,
      });
      dispatch(loginSuccess(res.data));
      navigate(`/dashboard`);
    } catch (error) {
      dispatch(loginFailure());
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (currentUser[0]?.token) navigate(`/dashboard`);
  }, [currentUser]);

  return (
    <>
      {!currentUser[0]?.token ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <div className="container-fluid">
            <div className="row h-100 align-items-center justify-content-center">
              <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                <div className="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                  <div className="d-flex flex-row-reverse align-items-center justify-content-between mb-3">
                    <img
                      src="/assests/Logo.svg"
                      alt="logo"
                      width="125"
                      height="100"
                    />
                    <h3>Sign In</h3>
                  </div>
                  <form onSubmit={Login}>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        required
                        placeholder="name@example.com"
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ color: "#FFFFFF" }}
                      />
                      <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                      className="form-floating mb-4"
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        required
                        style={{ color: "#FFFFFF" }}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <label htmlFor="floatingPassword">Password</label>
                      <button
                        type="button"
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: showPassword ? "#007bff" : "inherit",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <i className="fas fa-eye-slash"></i>
                        ) : (
                          <i className="fas fa-eye"></i>
                        )}
                      </button>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between mb-4">
                    <a href="a">Forgot Password</a>
                  </div> */}
                    <button
                      type="submit"
                      className="btn btn-primary py-3 w-100 mb-4"
                    >
                      Sign In
                    </button>
                  </form>
                  {/* <p className="text-center mb-0">
                Don't have an Account? <a href="/signup">Sign Up</a>
              </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Signin;
